'use client';

import { NextPage } from 'next';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBullseye, faSparkles, faCheck, faSparkle, IconDefinition, faWandMagicSparkles } from '@fortawesome/sharp-solid-svg-icons';
import Image from 'next/image';
import { OnboardingFormComponentProps } from '../utils/types';
import { UserDataFormType } from '../../dashboard/types';

interface FormStep {
    id: number;
    title: string;
    image?: string;
    icon?: IconDefinition;
}

export const onboardingFormSteps: FormStep[] = [
    {
        id: 0,
        title: 'Kartiv',
        image: '/assets/svg/kartivLogo.svg',
    },
    {
        id: 1,
        title: 'My Product',
        icon: faSparkle, 
    }
];

export const optimizeFormSteps: FormStep[] = [
    {
        id: 0,
        title: 'Optimize',
        icon: faWandMagicSparkles,
    },
    {
        id: 1,
        title: 'Your Roles',
        icon: faUser,
    },
    {
        id: 2,
        title: 'Your Goals',
        icon: faBullseye,
    },
]

const Navigation: NextPage<OnboardingFormComponentProps> = ({ currentStep, setCurrentStep, submitedForms, setSubmitedForms, skippedForms, userDataFormType }) => {
    const [formSteps, setFormSteps] = useState<FormStep[]>()
    const handleStepClick = (step: number) => {
      if (submitedForms.includes(step) || skippedForms.includes(step)) {
        setCurrentStep(step);
      }
    };
    useEffect(() => {
        if (userDataFormType && userDataFormType === UserDataFormType.OPTIMIZE) {
            setFormSteps(optimizeFormSteps);
        } else {
            setFormSteps(onboardingFormSteps);
        }
    },[userDataFormType])

    return (
        <div>
            <ul className="flex flex-col gap-8">
                {formSteps && formSteps.map((step, index) => {
                    return (
                        <li className="cursor-pointer" key={step.id + index} onClick={() => handleStepClick(step.id)}>
                            <button
                                type="button"
                                className={clsx(
                                    'relative inline-flex items-center gap-3 px-5 py-4 w-40 h-14 text-base font-medium text-center text-gray-800 bg-gray-50 border border-gray-200 rounded hover:bg-white focus:bg-white focus:outline-none focus:shadow-xl',
                                    { 'bg-gray-50/25 outline-none shadow-xl': currentStep === step.id },
                                    { 'pointer-events-none cursor-not-allowed': !submitedForms.includes(step.id) },
                                )}
                            >
                                {step.image && <Image src={step.image} alt="step.image" width={11} height={16} />}
                                {step.icon && <FontAwesomeIcon className="w-4 h-4" icon={step.icon} />}
                                {step.title}
                                <div
                                    className={clsx(
                                        'absolute inline-flex px-1 py-2 items-center justify-center w-6 h-6 text-xs text-black bg-gray-100 border border-gray-200 rounded-full -top-2 -right-2 dark:border-gray-900',
                                        { 'bg-green-100': submitedForms.includes(step.id) },
                                    )}
                                >
                                    {!submitedForms.includes(step.id) ? step.id + 1 : <FontAwesomeIcon className="" icon={faCheck} />}
                                </div>
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Navigation;
