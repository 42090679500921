import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import dataOperations from '../../../lib/UserSpace';
import { ProductSample, UIStepType, UpdateUserInput, User, UserAttribute } from '../../../src/API';
import { CognitoUser } from '@aws-amplify/auth';
import { onboardingFormSteps } from '../../onboarding/components/Navigation';
import { addCustomAnalyticsUserAttributes, AnalyticsEvents, track } from '../../../src/utils/AnalyticsUtils';
import { UserAttributesType, goals, roles, StepInDB } from '../../onboarding/utils/types';
import { lastOnboardingFormStep, lastOnboardingUIStep } from '../types';

export interface Step {
    id: number;
    data?: object;
    completedStepTypes?: UIStepType[];
    isSubmited: boolean;
}

export interface OnboardingFormState {
    cognitoUser: CognitoUser | undefined;
    user: any;
    steps: Step[];
    uiSteps: Step[];
    optimizeSteps: Step[];
    productSamples: ProductSample[];
    productSampleThumbnails: Map<string, string>;
    isOnboardingFormSubmited: boolean;
    isUIStepsSubmited: boolean;
    isFirstUIStepSubmited: boolean;
    currentFormStep: number;
    currentOptimizeFormStep: number;
    submitedFormStepsIds: number[];
    submitedOptimizeFormStepsIds: number[];

    setCognitoUser: (props: { user: CognitoUser }) => void;
    setCurrentStepToStore: (step: Step) => Promise<void>;
    setCurrentOptimizeStepToStore: (step: Step) => void;
    fetchProductSamples: () => Promise<void>;
    createUser: (props: { user: CognitoUser }) => Promise<User | undefined>;
    getOrCreateUserWithLimits: (props: { user: CognitoUser }) => Promise<User | undefined>;
    fetchUserDataOnly: (props: { user: CognitoUser }) => Promise<User | undefined>;
    updateUserRolesAndGoalsData: (props: { type: string; data: any }) => Promise<void>;
    setUserCancelingSubscriptionState: (props: { state: boolean }) => void;
    setUpdatedUser: (props: { user: User }) => void;
    updateUserData: (props: { updateUserInput: UpdateUserInput }) => Promise<void>;
}

const defaultValues = {
    cognitoUser: {} as CognitoUser,
    user: {},
    steps: [] as Step[],
    uiSteps: [] as Step[],
    optimizeSteps: [] as Step[],
    productSamples: [],
    productSampleThumbnails: new Map<string, string>(),
    isOnboardingFormSubmited: false,
    isUIStepsSubmited: false,
    isFirstUIStepSubmited: false,
    currentFormStep: 0,
    currentOptimizeFormStep: 0,
    submitedFormStepsIds: [],
    submitedOptimizeFormStepsIds: [],
};

const useOnboardingFormStore = create<OnboardingFormState>()(
    devtools(
        (set, get) => ({
            ...defaultValues,
            setCognitoUser: (props: { user: CognitoUser }) => {
                set(() => ({ cognitoUser: props.user }), false, 'onboardingForm/set cognitoUser');
            },
            setCurrentStepToStore: async (step: Step) => {
                let newSteps: Step[] = [];
                const currentStateSteps: Step[] = step.data ? get().steps : get().uiSteps;
                const consistStep: Step[] = currentStateSteps.filter((item) => item.id === step.id) || [];
                if (!consistStep.length) {
                    newSteps = [...currentStateSteps, step];
                } else {
                    currentStateSteps[step.id] = step;
                    newSteps.push(...currentStateSteps);
                }
                const cognitoUser = get().cognitoUser;
                if (step.data) {
                    set(() => ({ steps: [...newSteps], isOnboardingFormSubmited: true }), false, 'onboardingForm/set step');
                    const formatedNewSteps = newSteps.map((step) => {
                        return { ...step, data: JSON.stringify(step.data) };
                    });

                    if (cognitoUser) {
                        await dataOperations.updateUserOnboardingData(cognitoUser, formatedNewSteps);
                    }
                } else {
                    set(() => ({ uiSteps: [...newSteps], isUIStepsSubmited: true, isFirstUIStepSubmited: true }), false, 'onboardingForm/set step');
                    if (cognitoUser) {
                        await dataOperations.updateUserOnboardingData(cognitoUser, newSteps as StepInDB[]);
                    }
                }
            },
            setCurrentOptimizeStepToStore: (step: Step) => {
                let newOptimizeSteps: Step[] = [];
                const currentOptimizeSteps: Step[] = get().optimizeSteps;
                const consistStep: Step[] = currentOptimizeSteps.filter((item) => item.id === step.id) || [];
                if (!consistStep.length) {
                    newOptimizeSteps = [...currentOptimizeSteps, step];
                } else {
                    currentOptimizeSteps[step.id] = step;
                    newOptimizeSteps.push(...currentOptimizeSteps);
                }
                set(() => ({ optimizeSteps: [...newOptimizeSteps] }), false, 'onboardingForm/set optimize step');
            },
            fetchProductSamples: async () => {
                console.log('fetchProductSamples: start');
                try {
                    const productSamples = await dataOperations.getAllProductSamples();

                    for (const productSample of productSamples) {
                        if (!get().productSampleThumbnails.get(productSample.id)) {
                            const thumbnail = await dataOperations.getDataFromThumbnailPath(productSample.thumbnailPath, productSample.id);
                            get().productSampleThumbnails.set(productSample.id, URL.createObjectURL(thumbnail));
                        }
                    }

                    set(() => ({ productSamples }), false, 'useOnboardingFormStore/fetchProductSamples added');
                    console.log('fetchProductSamples: fetched');
                } catch (error) {
                    console.log('Failed to fetch product samples', error);
                }
            },
            createUser: async (props: { user: CognitoUser }): Promise<User | undefined> => {
                try {
                    const response = await dataOperations.createUser(props.user);
                    if (response) {
                        set(
                            () => ({
                                user: response,
                            }),
                            false,
                            'useOnboardingFormStore/createUser finished',
                        );
                    }
                    return response;
                } catch (error) {
                    console.log('Failed to create user', error);
                }
            },
            getOrCreateUserWithLimits: async (props: { user: CognitoUser }): Promise<User | undefined> => {
                try {
                    const response = await dataOperations.getOrCreateUserWithLimits(props.user);
                    if (response) {
                        const formatedSteps = response.onboarding.formSteps?.map((step) => {
                            if (step?.data) {
                                return { ...step, data: JSON.parse(step.data) };
                            }
                            return step;
                        });

                        const userRoles = response.roles ? response.roles.map((role: UserAttribute | null) => role && role.value) : [];
                        const rolesStepValues = roles.reduce((acc, rec) => {
                            return { ...acc, [rec.title]: userRoles.includes(rec.title) };
                        }, {});
                        const userGoals = response.goals ? response.goals.map((goal: UserAttribute | null) => goal && goal.value) : [];
                        const goalsStepValues = goals.reduce((acc, rec) => {
                            return { ...acc, [rec.title]: userGoals.includes(rec.title) };
                        }, {});
                        const optimizeData: Step[] = [
                            { id: 0, data: {}, isSubmited: userRoles.some((role) => role) },
                            { id: 1, data: rolesStepValues, isSubmited: userRoles.some((role) => role) },
                            { id: 2, data: goalsStepValues, isSubmited: userGoals.some((goal) => goal) },
                        ];
                        const submitedOptimizeFormStepsIds = optimizeData.filter((step) => step.isSubmited).map((step) => step.id);

                        const submitedOnboardingFormSteps = formatedSteps
                            ?.filter((step) => step?.isSubmited)
                            .map((step) => step && step.id);
                        const isUIStepsSubmited = response.onboarding.uiSteps?.filter((step) => step?.isSubmited);
                        const isFirstUIStepSubmited = isUIStepsSubmited?.some((step) => step?.id === 0);
                        const isLastForm = submitedOnboardingFormSteps?.length === onboardingFormSteps.length;
                        const lastSubmittedForm = isLastForm ? 0 : 1;
                        const currentFormStep = isLastForm ? submitedOnboardingFormSteps?.length - 1 : submitedOnboardingFormSteps?.length;
                        const submitedFormStepsIds = onboardingFormSteps
                            .map((onboardingFormStep) => {
                                if (currentFormStep && onboardingFormStep.id <= currentFormStep - lastSubmittedForm) {
                                    return onboardingFormStep.id;
                                }
                            })
                            .filter((onboardingFormStep) => onboardingFormStep !== undefined);
                        set(
                            () => ({
                                user: response,
                                steps: formatedSteps as Step[],
                                uiSteps: response.onboarding.uiSteps as Step[],
                                optimizeSteps: optimizeData,
                                isOnboardingFormSubmited: submitedOnboardingFormSteps?.includes(lastOnboardingFormStep - 1),
                                isUIStepsSubmited: isUIStepsSubmited?.length === lastOnboardingUIStep,
                                isFirstUIStepSubmited,
                                currentFormStep: currentFormStep as number,
                                currentOptimizeFormStep: submitedOptimizeFormStepsIds.length ? submitedOptimizeFormStepsIds.length - 1 : 0,
                                submitedFormStepsIds: submitedFormStepsIds as number[],
                                submitedOptimizeFormStepsIds,
                            }),
                            false,
                            'useOnboardingFormStore/fetchingUser finished',
                        );
                        addCustomAnalyticsUserAttributes(UserAttributesType.LIMITATION, response.limitation);
                    }
                    return response;
                } catch (error) {
                    console.log('Failed to fetch user', error);
                }
            },
            fetchUserDataOnly: async (props: { user: CognitoUser }) => {
                try {
                    const response = await dataOperations.fetchUserById(props.user);
                    if (response) {
                        return response;
                    }
                } catch (error) {
                    console.log('Failed to fetch user', error);
                    return;
                }
            },
            updateUserRolesAndGoalsData: async (props: { type: string; data: any }): Promise<void> => {
                try {
                    const formatedData = Object.keys(props.data)
                        .reduce((acc: any, rec: any, index: any) => {
                            return [...acc, { id: index, value: rec }];
                        }, [])
                        .filter((item) => props.data[item.value]);

                    const analytics =
                        props.type === UserAttributesType.ROLES
                            ? AnalyticsEvents.ONBOARDING_NEXT_PRESSED_ROLES_SCREEN
                            : AnalyticsEvents.ONBOARDING_NEXT_PRESSED_GOALS_SCREEN;
                    track(analytics, formatedData);
                    addCustomAnalyticsUserAttributes(props.type, props.data);

                    const cognitoUser = get().cognitoUser;
                    if (cognitoUser) {
                        const response = await dataOperations.updateUserRolesAndGoalsData(cognitoUser, props.type, formatedData);
                    }
                } catch (error) {
                    console.log('Failed to update user onboarding data', error);
                }
            },
            setUserCancelingSubscriptionState: (props: { state: boolean }): void => {
                const updatedUserData = { ...get().user, isSubscriptionCanceled: props.state };
                set(() => ({ user: updatedUserData }));
            },
            setUpdatedUser: (props: { user: User }) => {
                set(() => ({ user: props.user }))
            },
            updateUserData: async (props: { updateUserInput: UpdateUserInput }) => {
                const updatedUser = await dataOperations.updateUserData(props.updateUserInput);

                set({ user: updatedUser });
            }
        }),
        {
            name: 'onboardingForm-store',
        },
    ),
);

export default useOnboardingFormStore;
