'use client';

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { Product, ProductStatus } from '../../../src/API';
import { DashboardView, SessionStorageItemType } from '../types';
import dataOperations, { productHandler } from '../../../lib/UserSpace';

export interface DashboardState {
    update: boolean;
    currentView: DashboardView;
    hideSidebar: boolean;
    availableProducts: Product[];
    isProductsFetchingEnded: boolean;
    isUserUsingMobile: boolean | undefined;
    updateAfterDelete: (product: Product) => Promise<void>;
    productThumbnails: Map<string, string>;
    setCurrentView: (view: DashboardView) => void;
    fetchProducts: (initProductsState?: boolean) => Promise<void>;
    fetchProductById: (props: { productId: string, productThumbnail?: string }) => Promise<void>;
    getProductImageDownloadPath: (props: { productId: string }) => Promise<string>;
    resetProductsFetchingState: () => void;
    toggleSideBar: () => void;
    toggleSideBarFromStorage: (props: { sideBarCurrentState?: string | null }) => void;
    reset: () => void;
    updateProduct: (product: Product) => Promise<void>;
    setIsUserUsingMobile: (mobileState: boolean) => void;
}

const defaultValues = {
    currentView: DashboardView.Home,
    availableProducts: [],
    isProductsFetchingEnded: false,
    isUserUsingMobile: undefined,
    productThumbnails: new Map<string, string>(),
    hideSidebar: false,
    update: false,
};

const useDashboardStore = create<DashboardState>()(
    devtools(
        (set, get) => ({
            ...defaultValues,
            setCurrentView: (newView: DashboardView) => {
                set(() => ({ currentView: newView }), false, 'dashboard/set view');
            },
            fetchProducts: async (initProductsState?: boolean) => {
                set({ isProductsFetchingEnded: false });

                const products = await productHandler.getProducts(initProductsState);

                const newProductThumbnails = new Map(get().productThumbnails);
                let setNewMap = false;

                let allThumbnailPromises = [];
                for (const product of products) {
                    if (product.status === ProductStatus.AVAILABLE || product.status === ProductStatus.INACTIVE) {
                        if (!get().productThumbnails.get(product.id)) {
                            const thumbnailPromise = dataOperations.getProductThumbnail(product).then((thumbnail) => {
                                newProductThumbnails.set(product.id, URL.createObjectURL(thumbnail));
                            });
                            allThumbnailPromises.push(thumbnailPromise);

                            setNewMap = true;
                        }
                    }
                }

                await Promise.all(allThumbnailPromises);
                if (setNewMap) {
                    set({ productThumbnails: newProductThumbnails });
                }

                set({ availableProducts: products, isProductsFetchingEnded: true });
                return Promise.resolve();
            },
            fetchProductById: async (props: { productId: string, productThumbnail?: string }) => {
                const currentProduct = await dataOperations.getProduct(props.productId);
                const currentProductThumbnails = get().productThumbnails;
                const indexOfCurrentProductInFetchedProducts = get().availableProducts.findIndex((product) => product.id === props.productId);
                if (indexOfCurrentProductInFetchedProducts < 0) {
                    set({ availableProducts: [ ...get().availableProducts, currentProduct] });
                } else {
                    const updatedAvailableProducts = get().availableProducts.map((product, index) => {
                        if (index === indexOfCurrentProductInFetchedProducts) {
                            return currentProduct
                        } else {
                            return product
                        }
                    })
                    set({ availableProducts: updatedAvailableProducts });
                }
                if (props.productThumbnail) {
                    currentProductThumbnails.set(props.productId, props.productThumbnail);
                    set({ productThumbnails: currentProductThumbnails });
                }

            },
            getProductImageDownloadPath: async (props: { productId: string }) => {
                const productImageDownloadPath = await dataOperations.getProductThumbnailPresingedUrl(props.productId);

                return productImageDownloadPath.presignedDownloadUrl;
            },
            resetProductsFetchingState: () => {
                set({ isProductsFetchingEnded: false });
            },
            toggleSideBar: () => {
                window.sessionStorage.setItem(SessionStorageItemType.SIDEBAR_HIDDEN_STATE, String(!get().hideSidebar));
                set(() => ({ hideSidebar: !get().hideSidebar }), false, 'dashboard/toggle hide sidebar');
            },
            toggleSideBarFromStorage: (props: { sideBarCurrentState?: string | null }) => {
                set(() => ({ hideSidebar: props.sideBarCurrentState === 'true' }), false, 'dashboard/toggle hide sidebar');
            },
            updateAfterDelete: async (product: Product) => {
                set((prev) => ({
                    availableProducts: prev.availableProducts.filter((_) => _.id !== product.id),
                }));
            },
            reset: () => {
                set(() => ({ ...defaultValues }), false, 'dashboard/reset');
            },
            updateProduct: async (product: Product) => {
                const availableProduct = get().availableProducts;
                const productIndex = availableProduct.findIndex((_) => _.id === product.id);
                if (productIndex !== -1) {
                    availableProduct[productIndex] = product;
                    set({ availableProducts: availableProduct });
                } else {
                    console.error('updateProduct product not found');
                }
                await dataOperations.publishProduct(product);
            },
            setIsUserUsingMobile: (mobileState: boolean) => {
                set({ isUserUsingMobile: mobileState });
            },
        }),
        {
            name: 'dashboard-store',
        },
    ),
);

export default useDashboardStore;
