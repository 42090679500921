import * as React from 'react';
import { Auth } from 'aws-amplify';
import { CognitoAttributes } from '@aws-amplify/ui';
import { CognitoUser } from '@aws-amplify/auth';

export interface CognitoUserAttributes extends CognitoAttributes {
    given_name: string;
    family_name: string;
    initial: string;
    fullName: string;
}

export enum UserState {
    Initial,
    Authenticated,
    NotAuthenticated,
}

export const useCognitoUser = () => {
    const [userAttributes, setUserAttributes] = React.useState<CognitoUserAttributes>();
    const [user, setUser] = React.useState<CognitoUser>();
    const [userState, setUserState] = React.useState<UserState>(UserState.Initial);

    React.useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((response) => {
                
                let attributes;

                if (response.signInUserSession && response.signInUserSession.idToken) {
                    // The user is authenticated using a social identity provider (e.g., Google)
                    attributes = response.signInUserSession.idToken.payload;
                } else {
                    // The user is authenticated using email/password directly with Cognito
                    attributes = response.attributes;
                }

                setUserAttributes({
                    ...attributes,
                    initial: attributes.given_name[0],
                    fullName: `${attributes.given_name} ${attributes.family_name}`,
                });
                setUser(response);
                setUserState(UserState.Authenticated);
            })
            .catch((e) => {
                console.log('Not authorized', e);
                setUserState(UserState.NotAuthenticated);
            });
    }, []);

    return [userAttributes, userState, user] as const;
};
